import { agreementtoRevive } from "./Colorado/agreementtoRevive";
import { assignmentofLease } from "./Colorado/assignmentofLease";
import { amendExtendContract } from "./Colorado/amendExtendContract";
import { amendExtendContract2023 } from "./Colorado/amendExtendContract2023";
import { amendExtendWithoutDates } from "./Colorado/amendExtendWithoutDates";
import { appraisalValueObjectionNotice } from "./Colorado/appraisalValueObjectionNotice";
import { billofSaleContract } from "./Colorado/billofSaleContract";
import { billofSalePPA } from "./Colorado/billofSalePPA";
import { brokerageDisclosuretoBuyer } from "./Colorado/brokerageDisclosuretoBuyer";
import { brokerageDisclosuretoSellerFSBO } from "./Colorado/brokerageDisclosuretoSellerFSBO";
import { brokerageDutiesDisclosuretoSellernonCRECListing } from "./Colorado/brokerageDutiesDisclosuretoSellernonCRECListing";
import { buyerContractAmendExtend2023 } from "./Colorado/buyerContractAmendExtend2023";
import { changeofStatus } from "./Colorado/changeofStatus";
import { contracttoBuyandSellCOForeclosureProtectionAct } from "./Colorado/contracttoBuyandSellCOForeclosureProtectionAct";
import { contracttoBuyandSellCommercial } from "./Colorado/contracttoBuyandSellCommercial";
import { contracttoBuyandSellIncome } from "./Colorado/contracttoBuyandSellIncome";
import { contracttoBuyandSellLand } from "./Colorado/contracttoBuyandSellLand";
import { contracttoBuyandSellResidential } from "./Colorado/contracttoBuyandSellResidential";
import { counterproposal } from "./Colorado/counterproposal";
import { counterproposal2023 } from "./Colorado/counterproposal2023";
import { cOVID19Addendum2020 } from "./Colorado/cOVID19Addendum2020";
import { definitionsofWorkingRelationshipsForBuyers } from "./Colorado/definitionsofWorkingRelationshipsForBuyers";
import { definitionsofWorkingRelationshipsForSellers } from "./Colorado/definitionsofWorkingRelationshipsForSellers";
import { earnestMoneyReceipt } from "./Colorado/earnestMoneyReceipt";
import { earnestMoneyRelease } from "./Colorado/earnestMoneyRelease";
import { emptyForm } from "./emptyForm";
import { energyBenchmarkingDisclosure } from "./Colorado/energyBenchmarkingDisclosure";
import { estoppelStatement } from "./Colorado/estoppelStatement";
import { exchangeAddendumtoContracttoBuySellRealEstate } from "./Colorado/exchangeAddendumtoContracttoBuySellRealEstate";
import { exclusiveRighttoBuy } from "./Colorado/exclusiveRighttoBuy";
import { exclusiveRighttoSell2024 } from "./Colorado/exclusiveRighttoSell2024";
import { exclusiveRighttoSell } from "./Colorado/exclusiveRighttoSell";
import { exclusiveRighttoBuy2023 } from "./Colorado/exclusiveRighttoBuy2023";
import { extensionorTermination } from "./Colorado/extensionorTermination";
import { greenDisclosure } from "./Colorado/greenDisclosure";
import { inspectionObjectionNotice } from "./Colorado/inspectionObjectionNotice";
import { inspectionResolution } from "./Colorado/inspectionResolution";
import { inspectionResolution2023 } from "./Colorado/inspectionResolution2023";
import { leadBasedPaintDisclosureSalesWritable1 } from "./Colorado/leadBasedPaintDisclosureSalesWritable1";
import { listingContractAmendExtendCleanWritable2020 } from "./Colorado/listingContractAmendExtendCleanWritable2020";
import { manufacturedHomeAddendum2024 } from "./Colorado/manufacturedHomeAddendum2024";
import { manufacturedHomeAmendExtend2024 } from "./Colorado/manufacturedHomeAmendExtend2024";
import { manufacturedHomeCBSLotLease2024 } from "./Colorado/manufacturedHomeCBSLotLease2024";
import { manufacturedHomeCounterproposal2024 } from "./Colorado/manufacturedHomeCounterproposal2024";
import { noticeToTerminate } from "./Colorado/noticeToTerminate";
import { noticetoTerminateBuyer } from "./Colorado/noticetoTerminateBuyer";
import { noticetoTerminateSeller } from "./Colorado/noticetoTerminateSeller";
import { openListingAddendumtoListingContract } from "./Colorado/openListingAddendumtoListingContract";
import { personalPropertyAgreement } from "./Colorado/personalPropertyAgreement";
import { postClosingOccupancyAgreement } from "./Colorado/postClosingOccupancyAgreement";
import { residentialAddendumtoContracttoBuySellRealEstate } from "./Colorado/residentialAddendumtoContracttoBuySellRealEstate";
import { sellersPropertyDisclosureLand } from "./Colorado/sellersPropertyDisclosureLand";
import { sellersPropertyDisclosureResidential } from "./Colorado/sellersPropertyDisclosureResidential";
import { shortSaleAddendumCBS } from "./Colorado/shortSaleAddendumCBS";
import { shortSaleAddendumSellerListingContract } from "./Colorado/shortSaleAddendumSellerListingContract";
import { sourceOfWaterAddendum } from "./Colorado/sourceOfWaterAddendum";
import { squareFootageDisclosure } from "./Colorado/squareFootageDisclosure";
import { titleRelatedObjectionNotice } from "./Colorado/titleRelatedObjectionNotice";
import { contracttoBuyandSellResidential2023 } from "./Colorado/contracttoBuyandSellResidential2023";
import { sellersPropertyDisclosureResidential2023 } from "./Colorado/sellersPropertyDisclosureResidential2023";
import { contracttoBuyandSellIncomeResidential2023 } from "./Colorado/contracttoBuyandSellIncome2023";
import { contracttoBuyandSellForeclosure2023 } from "./Colorado/contracttoBuyandSellForeclosure2023";
import { oRDERTITLEFORM } from "./Colorado/oRDERTITLEFORM";
import { rBCommissionsDisclosure1023 } from "./Colorado/rBCommissionsDisclosure1023";
import { rBHomeInspectionWaiverAgreement23 } from "./Colorado/rBHomeInspectionWaiverAgreement23";
import { rBBrokerRebatetoBuyer2022 } from "./Colorado/rBBrokerRebatetoBuyer2022";
import { closingInstructions } from "./Colorado/closingInstructions";
import { rBSellerComingSoonAddendum2022 } from "./Colorado/rBSellerComingSoonAddendum2022";
import { homeWarrantyAHS2023 } from "./Colorado/homeWarrantyAHS2023";
import { sellersPropertyDisclosureLand2024 } from "./Colorado/sellersPropertyDisclosureLand2024";
import { listingContractAmendExtend2023 } from "./Colorado/listingContractAmendExtend2023";
import { inspectionObjectionNotice2023 } from "./Colorado/inspectionObjectionNotice2023";
import { contracttoBuyandSellLand2024 } from "./Colorado/contracttoBuyandSellLand2024";
import { residentialAddendumtoContract2024 } from "./Colorado/residentialAddendumtoContract2024";
import { sellersPropertyDisclosureSupplementAdditionalStructure } from "./Colorado/sellersPropertyDisclosureSupplementAdditionalStructure";
// CB 
import { cBExclusiveRighttoSell2023 } from "./Colorado/cBExclusiveRighttoSell2023";
import { cBExclusiveRighttoBuy2023 } from "./Colorado/cBExclusiveRighttoBuy2023";
import { cBColoradoAffiliatedBusinessDisclosure } from "./Colorado/cBColoradoAffiliatedBusinessDisclosure";
import { cBMoldDisclosure } from "./Colorado/cBMoldDisclosure";
import { cBSalesAssociateElectionPersonalTransactions } from "./Colorado/cBSalesAssociateElectionPersonalTransactions";
import { cBRadonDisclosure } from "./Colorado/cBRadonDisclosure";
import { cBWireFraudDisclosure } from "./Colorado/cBWireFraudDisclosure";
import { cBCExclusiveRighttoBuy2023 } from "./Colorado/cBCExclusiveRighttoBuy2023";
import { cBCExclusiveRighttoSell2023 } from "./Colorado/cBCExclusiveRighttoSell2023";
import { exclusiveRighttoSell2024CB } from "./Colorado/exclusiveRighttoSell2024CB";

// CBDP 
import { cBDPAffiliatedBusinesses2022 } from "./Colorado/cBDPAffiliatedBusinesses2022";
import { affiliatedBusinessDisclosureCBDP } from "./Colorado/affiliatedBusinessDisclosureCBDP";
//HSPR 
import { listingTerminationAgreementHSPR } from "./Colorado/listingTerminationAgreementHSPR";
import { homeWarrantyInspectionFormHSPR } from "./Colorado/homeWarrantyInspectionFormHSPR";
import { fSBOCommissionAgreementHSPR } from "./Colorado/fSBOCommissionAgreementHSPR";
import { referralAgreementReceivingAgentHSPR } from "./Colorado/referralAgreementReceivingAgentHSPR";
import { introlendandPreferredTitleABAHSPR } from "./Colorado/introlendandPreferredTitleABAHSPR";
import { referralAgreementOriginatingAgentHSPR } from "./Colorado/referralAgreementOriginatingAgentHSPR";
// REMAX
import { rEMAXWireFraudAlert } from "./Colorado/rEMAXWireFraudAlert";
// LOUISIANA FORMS *********************************************
import { agencyDisclosureForm } from "./Louisiana/agencyDisclosureForm";
import { buySellAgreement2019 } from "./Louisiana/buySellAgreement2019";
import { leadBasedPaintWarning } from "./Louisiana/leadBasedPaintWarning";
import { affiliateBusinessArrangementHSPR } from "./Colorado/affiliateBusinessArrangementHSPR";
import { addendumBlank } from "./Colorado/addendumBlank";
import { onetoFourFamilyResidentialContract } from "./Texas/oneToFourFamilyResidentialContract";
import { amendment } from "./Texas/amendment";
import { farmandRanchContract } from "./Texas/farmandRanchContract";
import { noticetoPurchaserofSpecialTaxingDistrict } from "./Texas/noticetoPurchaserofSpecialTaxingDistrict";
import { residentialCondominiumContract } from "./Texas/residentialCondominiumContract";
import { unimprovedPropertyContract } from "./Texas/unimprovedPropertyContract";
import { subdivisionandPropertyOwnersAssociation } from "./Texas/subdivisionandPropertyOwnersAssociation";
import { sellersTemporaryResidentialLease } from "./Texas/sellersTemporaryResidentialLease";
import { addendumRegardingResidentialLeases } from "./Texas/addendumRegardingResidentialLeases";
import { noticeofBuyersTermination } from "./Texas/noticeofBuyersTermination";
import { condominiumResaleCertificate } from "./Texas/condominiumResaleCertificate";
import { addendumCoastalAreaProperty } from "./Texas/addendumCoastalAreaProperty";
import { addendumNoticeofObligationtoPayImprovementDistrictAssessment } from "./Texas/addendumNoticeofObligationtoPayImprovementDistrictAssessment";
import { addendumBackUpContract } from "./Texas/addendumBackUpContract";
import { addendumPropertyLocatedSeawardofGulfWaterway } from "./Texas/addendumPropertyLocatedSeawardofGulfWaterway";
import { addendumAuthorizingHydrostaticTesting } from "./Texas/addendumAuthorizingHydrostaticTesting";
import { addendumPropaneGasArea } from "./Texas/addendumPropaneGasArea";
import { addendumMandatoryMembershipinOwnersAssociation } from "./Texas/addendumMandatoryMembershipinOwnersAssociation";
import { addendumReservationofOilGasMinerals } from "./Texas/addendumReservationofOilGasMinerals";
import { addendumSaleofOtherPropertybyBuyer } from "./Texas/addendumSaleofOtherPropertybyBuyer";
import { addendumNonRealtyItems } from "./Texas/addendumNonRealtyItems";
import { addendumLeadBasedPaint } from "./Texas/addendumLeadBasedPaint";
import { addendumLoanAssumption } from "./Texas/addendumLoanAssumption";
import { addendumShortSale } from "./Texas/addendumShortSale";
import { landlordFloodplainandFloodNotice } from "./Texas/landlordFloodplainandFloodNotice";
import { addendumEnvironmentalAssessment } from "./Texas/addendumEnvironmentalAssessment";
import { addendumRegardingFixtureLeases } from "./Texas/addendumRegardingFixtureLeases";
import { buyersTemporaryResidentialLease } from "./Texas/buyersTemporaryResidentialLease";
import { addendumReleaseofLiabilityonAssumedLoan } from "./Texas/addendumReleaseofLiabilityonAssumedLoan";
import { addendumSellerFinancing } from "./Texas/addendumSellerFinancing";
import { newHomeContractCompletedConstruction } from "./Texas/newHomeContractCompletedConstruction";
import { newHomeContractIncompleteConstruction } from "./Texas/newHomeContractIncompleteConstruction";
import { addendumRighttoTerminateDuetoAppraisal } from "./Texas/addendumRighttoTerminateDuetoAppraisal";
//Oklahoma
// import { 201311cfpbkbyoclosingdisclosure } from "./201311cfpbkbyoclosingdisclosure";
import { accessoriesSupplement } from "./Oklahoma/accessoriesSupplement";
import { acknowledgmentandConfirmationofDisclosures } from "./Oklahoma/acknowledgmentandConfirmationofDisclosures";
import { affidavitforTitleorOwnershipofLandIndividual } from "./Oklahoma/affidavitforTitleorOwnershipofLandIndividual";
import { appendixAResidentialPropertyCondition } from "./Oklahoma/appendixAResidentialPropertyCondition";
import { appendixBRPCDisclaimer } from "./Oklahoma/appendixBRPCDisclaimer";
import { backUpSupplement } from "./Oklahoma/backUpSupplement";
import { blankAddendum } from "./Oklahoma/blankAddendum";
import { brokerServicesInformation } from "./Oklahoma/brokerServicesInformation";
import { buyerBrokerServiceAgreement } from "./Oklahoma/buyerBrokerServiceAgreement";
import { buyersCounteroffertoSellersCounteroffer } from "./Oklahoma/buyersCounteroffertoSellersCounteroffer";
import { buyersRemovalofConditionNotice } from "./Oklahoma/buyersRemovalofConditionNotice";
import { conditionedonSalePresentlyUnderContract } from "./Oklahoma/conditionedonSalePresentlyUnderContract";
import { condominiumAssociation } from "./Oklahoma/condominiumAssociation";
import { conventionalLoan } from "./Oklahoma/conventionalLoan";
import { disclosureofBrokerageServiceBuyer } from "./Oklahoma/disclosureofBrokerageServiceBuyer";
import { disclosureofBrokerageServiceSeller } from "./Oklahoma/disclosureofBrokerageServiceSeller";
import { disclosureofFamilialandBeneficialInterest } from "./Oklahoma/disclosureofFamilialandBeneficialInterest";
import { earnestMoneyForm } from "./Oklahoma/earnestMoneyForm";
import { escalationAddendum } from "./Oklahoma/escalationAddendum";
import { fHALoan } from "./Oklahoma/fHALoan";
import { farmRanchRecConventionalLoan } from "./Oklahoma/farmRanchRecConventionalLoan";
import { farmRanchRecLandExhibitA } from "./Oklahoma/farmRanchRecLandExhibitA";
import { farmRanchRecLandExhibitB } from "./Oklahoma/farmRanchRecLandExhibitB";
import { farmRanchRecLandRemovalofLivestock } from "./Oklahoma/farmRanchRecLandRemovalofLivestock";
import { farmandRanch } from "./Oklahoma/farmandRanch";
import { forYourProtectionGetaHomeInspection } from "./Oklahoma/forYourProtectionGetaHomeInspection";
import { landWithorWithoutDwelling } from "./Oklahoma/landWithorWithoutDwelling";
import { leadBasedPaintSellerDisclosure } from "./Oklahoma/leadBasedPaintSellerDisclosure";
import { legalDescriptionSupplement } from "./Oklahoma/legalDescriptionSupplement";
import { licenseeDisclosureRPCD } from "./Oklahoma/licenseeDisclosureRPCD";
import { listingAgreementAmendorExtend } from "./Oklahoma/listingAgreementAmendorExtend";
import { listingAgreement } from "./Oklahoma/listingAgreement";
import { nativeAmericanHomeLoan } from "./Oklahoma/nativeAmericanHomeLoan";
import { newHomeConstruction } from "./Oklahoma/newHomeConstruction";
import { noticeofCancellation } from "./Oklahoma/noticeofCancellation";
import { noticeofTRR } from "./Oklahoma/noticeofTRR";
import { propertyDataForm } from "./Oklahoma/propertyDataForm";
import { rPCDExemptionForm } from "./Oklahoma/rPCDExemptionForm";
import { releaseofContractandDisbursementofEarnestMoney } from "./Oklahoma/releaseofContractandDisbursementofEarnestMoney";
import { residentialListingExclusiveRighttoLease } from "./Oklahoma/residentialListingExclusiveRighttoLease";
import { residentialPropertyDisclosureAct012014 } from "./Oklahoma/residentialPropertyDisclosureAct012014";
import { residentialSale } from "./Oklahoma/residentialSale";
import { saleofBuyersPropertyConditionNotUnderContract } from "./Oklahoma/saleofBuyersPropertyConditionNotUnderContract";
import { sellersConditionRemovalDemandNotification } from "./Oklahoma/sellersConditionRemovalDemandNotification";
import { sellersCounteroffer } from "./Oklahoma/sellersCounteroffer";
import { singleFamilyHomeownersAssociation } from "./Oklahoma/singleFamilyHomeownersAssociation";
import { standardClauses } from "./Oklahoma/standardClauses";
import { supplement } from "./Oklahoma/supplement";
import { townhouseAssociation } from "./Oklahoma/townhouseAssociation";
import { uSDARuralHousingLoan } from "./Oklahoma/uSDARuralHousingLoan";
import { vALoan } from "./Oklahoma/vALoan";
import { vacantLotorTract } from "./Oklahoma/vacantLotorTract";
import { wireAdvisory } from "./Oklahoma/wireAdvisory";
import { counterproposalWithoutDates } from "./Colorado/counterproposalWithoutDates";
import { terminationofMarketingEffortsCBDP } from "./Colorado/terminationofMarketingEffortsCBDP";
import { assumptionofLoan } from "./Oklahoma/assumptionofLoan";
import { fortCollinsOccupancyDisclosure } from "./Colorado/fortCollinsOccupancyDisclosure";
import { cBFSBOAgreementforPaymentofCommission } from "./Colorado/cBFSBOAgreementforPaymentofCommission";
import { addendumThirdPartyFinancing } from "./Texas/addendumThirdPartyFinancing";
import { sellersDisclosureNotice } from "./Texas/sellersDisclosureNotice";
import { informationAboutBrokerageServices } from "./Texas/informationAboutBrokerageServices";
import { residentialBuyerRepresentation } from "./Texas/residentialBuyerRepresentation";
import { disclosureofRelationshipwithContractProvider } from "./Texas/disclosureofRelationshipwithContractProvider";
import { leadBasedPaintObligationsofSeller } from "./Colorado/leadBasedPaintObligationsofSeller";
import { sellerWarningCOForeclosureProtectionAct } from "./Colorado/sellerWarningCOForeclosureProtectionAct";
import { exclusiveBrokerageAddendumtoListingContract } from "./Colorado/exclusiveBrokerageAddendumtoListingContract";
import { cRENDelayedEntryForm } from "./Colorado/cRENDelayedEntryForm";
import { gTARSellerCounteroffer } from "./Oklahoma/gTARSellerCounteroffer";
import { gTARSupplement } from "./Oklahoma/gTARSupplement";
import { gTARVALoan } from "./Oklahoma/gTARVALoan";
import { gTARUSDARuralLoan } from "./Oklahoma/gTARUSDARuralLoan";
import { gTARConventionalLoan } from "./Oklahoma/gTARConventionalLoan";
import { gTAREarnestMoneyInstructions } from "./Oklahoma/gTAREarnestMoneyInstructions";
import { gTARFHALoan } from "./Oklahoma/gTARFHALoan";
import { gTARHistoricZoning } from "./Oklahoma/gTARHistoricZoning";
import { gTARSalesContract } from "./Oklahoma/gTARSalesContract";
import { gTARListingAgreement12024rev4 } from "./Oklahoma/gTARListingAgreement12024rev4";
import { gTARMLSComingSoonSellerAuthorization } from "./Oklahoma/gTARMLSComingSoonSellerAuthorization";
import { affiliatedBusinessDisclosureCB_DP } from "./Colorado/affiliatedBusinessDisclosureCB_DP";
import { terminationofMarketingEffortsCB_DP } from "./Colorado/terminationofMarketingEffortsCB_DP";
import { commissionDisbursementNextHomeVirtual } from "./Colorado/commissionDisbursementNextHomeVirtual";
// import { agencyDisclosurePamphlet } from "./Louisiana/agencyDisclosurePamphlet";
import { agencyDisclosureDualAgent } from "./Louisiana/agencyDisclosureDualAgent";
import { exclusiveBuyerRepresentationsAgreement } from "./Louisiana/exclusiveBuyerRepresentationsAgreement";
import { residentialAgreementtoBuyorSell } from "./Louisiana/residentialAgreementtoBuyorSell";
import { inspectionResponseAddenda } from "./Louisiana/inspectionResponseAddenda";
import { sewerTreatmentSystemsAddendum } from "./Louisiana/sewerTreatmentSystemsAddendum";
import { waiverofWarranty } from "./Louisiana/waiverofWarranty";
import { historicDistrictDisclosure } from "./Louisiana/historicDistrictDisclosure";
import { condominiumAddendum } from "./Louisiana/condominiumAddendum";
import { depositAddendum } from "./Louisiana/depositAddendum";
import { newConstructionAddendum } from "./Louisiana/newConstructionAddendum";
import { propertyDisclosureDocument } from "./Louisiana/propertyDisclosureDocument";
import { realVitalizeSellerPropertyAgreement } from "./Colorado/realVitalizeSellerPropertyAgreement";
import { exclusiveRighttoBuyCB2024 } from "./Colorado/exclusiveRighttoBuyCB2024";
import { sellersPropertyDisclosureCommercial } from "./Colorado/sellersPropertyDisclosureCommercial";
import { exclusiveRighttoSell2024Aug } from "./Colorado/exclusiveRighttoSell2024Aug";
import { brokerageFirmCompensationAgreement } from "./Colorado/brokerageFirmCompensationAgreement";
import { contracttoBuyandSellResidential2024 } from "./Colorado/contracttoBuyandSellResidential2024";
import { brokerageDisclosuretoBuyer2024 } from "./Colorado/brokerageDisclosuretoBuyer2024";

import { contracttoBuyandSellLand2024Aug } from "./Colorado/contracttoBuyandSellLand2024Aug";
import { exclusiveRighttoBuyCB2024Aug } from "./Colorado/exclusiveRighttoBuyCB2024Aug";
import { exclusiveRighttoSellCB2024Aug } from "./Colorado/exclusiveRighttoSellCB2024Aug";
import { exclusiveRighttoBuy2024Aug } from "./Colorado/exclusiveRighttoBuy2024Aug";
import { contracttoBuyandSellIncomeResidential2024 } from "./Colorado/contracttoBuyandSellIncomeResidential2024";
import { contracttoBuyandSellCommercial2024 } from "./Colorado/contracttoBuyandSellCommercial2024";
import { contracttoBuyandSellForeclosure2024 } from "./Colorado/contracttoBuyandSellForeclosure2024";
import { additionalDesignatedBrokerDC616 } from "./Colorado/additionalDesignatedBrokerDC616";
import { buyerAdvisoryResidentialDC924 } from "./Colorado/buyerAdvisoryResidentialDC924";
import { sellerAdvisoryResidentialDC924 } from "./Colorado/sellerAdvisoryResidentialDC924";
import { terminationAgreementBuyerDC523 } from "./Colorado/terminationAgreementBuyerDC523";
import { terminationAgreementSellerDC523 } from "./Colorado/terminationAgreementSellerDC523";
import { disclosureofBuyerBrokerCompensation92324 } from "./Colorado/disclosureofBuyerBrokerCompensation92324";
import { exclusiveRighttoSellCB202409 } from "./Colorado/exclusiveRighttoSellCB202409";



export function getFormFieldData(formTitle, transaction) {
  let formFieldData = [];
  if (transaction?.agentProfile?.state === "Oklahoma") {
    switch (formTitle) {
      // case "201311_cfpb_kbyo_closing-disclosure":
      //      formFieldData = 201311cfpbkbyoclosingdisclosure();
      //      break;
         case "Accessories Supplement":
           formFieldData = accessoriesSupplement();
           break;
         case "Acknowledgment and Confirmation of Disclosures":
           formFieldData = acknowledgmentandConfirmationofDisclosures();
           break;
          case "Affidavit for Title or Ownership of Land - Individual":
           formFieldData = affidavitforTitleorOwnershipofLandIndividual();
           break;
         case "Appendix A Residential Property Condition":
           formFieldData = appendixAResidentialPropertyCondition();
           break;
         case "Appendix B RPC Disclaimer ":
           formFieldData = appendixBRPCDisclaimer();
        break;
        case "Assumption of Loan":
           formFieldData = assumptionofLoan();
           break;
         case "Back-Up Supplement":
           formFieldData = backUpSupplement();
           break;
         case "Blank Addendum":
           formFieldData = blankAddendum();
           break;
         case "Broker Services Information":
           formFieldData = brokerServicesInformation();
           break;
         case "Buyer Broker Service Agreement":
           formFieldData = buyerBrokerServiceAgreement();
           break;
         case "Buyers Counteroffer to Sellers Counteroffer":
           formFieldData = buyersCounteroffertoSellersCounteroffer();
           break;
        case "Buyers Removal of Condition Notice":
           formFieldData = buyersRemovalofConditionNotice();
           break;
         case "Conditioned on Sale - Presently Under Contract":
           formFieldData = conditionedonSalePresentlyUnderContract();
           break;
         case "Condominium Association":
           formFieldData = condominiumAssociation();
           break;
         case "Conventional Loan":
           formFieldData = conventionalLoan();
           break;
         case "Disclosure of Brokerage Service - Buyer":
           formFieldData = disclosureofBrokerageServiceBuyer();
           break;
         case "Disclosure of Brokerage Service - Seller":
           formFieldData = disclosureofBrokerageServiceSeller();
           break;
         case "Disclosure of Familial and Beneficial Interest":
           formFieldData = disclosureofFamilialandBeneficialInterest();
           break;
         case "Earnest Money Form":
           formFieldData = earnestMoneyForm();
           break;
         case "Escalation Addendum":
           formFieldData = escalationAddendum();
           break;
         case "FHA Loan":
           formFieldData = fHALoan();
           break;
         case "Farm Ranch Rec Conventional Loan":
           formFieldData = farmRanchRecConventionalLoan();
           break;
         case "Farm Ranch Rec Land Exhibit A":
           formFieldData = farmRanchRecLandExhibitA();
           break;
         case "Farm Ranch Rec Land Exhibit B":
           formFieldData = farmRanchRecLandExhibitB();
           break;
         case "Farm Ranch Rec Land Removal of Livestock":
           formFieldData = farmRanchRecLandRemovalofLivestock();
           break;
         case "Farm and Ranch":
           formFieldData = farmandRanch();
           break;
         case "For Your Protection Get a Home Inspection":
           formFieldData = forYourProtectionGetaHomeInspection();
        break;
      case "GTAR Conventional Loan":
            formFieldData = gTARConventionalLoan();
        break;
      case "GTAR Earnest Money Instructions":
            formFieldData = gTAREarnestMoneyInstructions();
        break;
      case "GTAR FHA Loan":
            formFieldData = gTARFHALoan();
        break;
      case "GTAR Historic Zoning":
            formFieldData = gTARHistoricZoning();
        break;
      case "GTAR Listing Agreement 1-2024-rev-4":
            formFieldData = gTARListingAgreement12024rev4();
        break;
      case "GTAR MLS Coming Soon Seller Authorization":
        formFieldData = gTARMLSComingSoonSellerAuthorization();
        break;
      case "GTAR Sales Contract":
            formFieldData = gTARSalesContract();
          break;
        case "GTAR Seller Counteroffer":
            formFieldData = gTARSellerCounteroffer();
          break;
        case "GTAR Supplement":
           formFieldData = gTARSupplement();
           break;
        case "GTAR VA Loan":
           formFieldData = gTARVALoan();
           break;
        case "GTAR USDA Rural Loan":
           formFieldData = gTARUSDARuralLoan();
          break;
      
         case "Land With or Without Dwelling":
           formFieldData = landWithorWithoutDwelling();
           break;
         case "Lead-Based Paint Seller Disclosure":
           formFieldData = leadBasedPaintSellerDisclosure();
           break;
         case "Legal Description Supplement":
           formFieldData = legalDescriptionSupplement();
           break;
         case "Licensee Disclosure RPCD":
           formFieldData = licenseeDisclosureRPCD();
           break;
         case "Listing Agreement Amend or Extend":
           formFieldData = listingAgreementAmendorExtend();
           break;
         case "Listing Agreement":
           formFieldData = listingAgreement();
           break;
         case "Native American Home Loan":
           formFieldData = nativeAmericanHomeLoan();
           break;
         case "New Home Construction":
           formFieldData = newHomeConstruction();
           break;
         case "Notice of Cancellation":
           formFieldData = noticeofCancellation();
           break;
         case "Notice of TRR":
           formFieldData = noticeofTRR();
           break;
         case "Property Data Form":
           formFieldData = propertyDataForm();
           break;
         case "RPCD Exemption Form":
           formFieldData = rPCDExemptionForm();
           break;
         case "Release of Contract and Disbursement of Earnest Money":
           formFieldData = releaseofContractandDisbursementofEarnestMoney();
           break;
         case "Residential Listing - Exclusive Right to Lease":
           formFieldData = residentialListingExclusiveRighttoLease();
           break;
         case "Residential Property Disclosure Act 01- 2014":
           formFieldData = residentialPropertyDisclosureAct012014();
           break;
         case "Residential Sale":
           formFieldData = residentialSale();
           break;
         case "Sale of Buyers Property Condition - Not Under Contract":
           formFieldData = saleofBuyersPropertyConditionNotUnderContract();
           break;
         case "Sellers Condition Removal Demand Notification":
           formFieldData = sellersConditionRemovalDemandNotification();
           break;
         case "Sellers Counteroffer":
           formFieldData = sellersCounteroffer();
           break;
         case "Single Family Homeowners Association":
           formFieldData = singleFamilyHomeownersAssociation();
           break;
         case "Standard Clauses":
           formFieldData = standardClauses();
           break;
         case "Supplement":
           formFieldData = supplement();
           break;
         case "Townhouse Association":
           formFieldData = townhouseAssociation();
           break;
         case "USDA Rural Housing Loan":
           formFieldData = uSDARuralHousingLoan();
           break;
         case "VA Loan":
           formFieldData = vALoan();
           break;
         case "Vacant Lot or Tract":
           formFieldData = vacantLotorTract();
           break;
         case "Wire Advisory":
           formFieldData = wireAdvisory();
           break;

      default:
        formFieldData = emptyForm();
    }
  }
  else if (transaction?.agentProfile?.state === "Texas") {
    switch (formTitle) {
       case "Addendum Authorizing Hydrostatic Testing":
           formFieldData = addendumAuthorizingHydrostaticTesting();
           break;
      case "Addendum Back-Up Contract":
           formFieldData = addendumBackUpContract();
           break;
      case "Addendum Coastal Area Property":
           formFieldData = addendumCoastalAreaProperty();
        break;
      case "Addendum Environmental Assessment":
           formFieldData = addendumEnvironmentalAssessment();
           break;
      case "Addendum Lead Based Paint":
           formFieldData = addendumLeadBasedPaint();
        break;
      case "Addendum Loan Assumption":
           formFieldData = addendumLoanAssumption();
        break;
      case "Addendum Mandatory Membership in Owners Association":
           formFieldData = addendumMandatoryMembershipinOwnersAssociation();
        break;
      case "Addendum Non-Realty Items":
           formFieldData = addendumNonRealtyItems();
           break;
      case "Addendum Notice of Obligation to Pay Improvement District Assessment":
           formFieldData = addendumNoticeofObligationtoPayImprovementDistrictAssessment();
        break;
      case "Addendum Propane Gas Area":
           formFieldData = addendumPropaneGasArea();
           break;
      case "Addendum Property Located Seaward of Gulf Waterway":
           formFieldData = addendumPropertyLocatedSeawardofGulfWaterway();
        break;
      case "Addendum Regarding Fixture Leases":
           formFieldData = addendumRegardingFixtureLeases();
           break;
      case "Addendum Regarding Residential Leases":
           formFieldData = addendumRegardingResidentialLeases();
        break;
      case "Addendum Release of Liability on Assumed Loan":
           formFieldData = addendumReleaseofLiabilityonAssumedLoan();
           break; 
      case "Addendum Reservation of Oil Gas Minerals":
          formFieldData = addendumReservationofOilGasMinerals();
          break;
      case "Addendum Right to Terminate Due to Appraisal":
        formFieldData = addendumRighttoTerminateDuetoAppraisal();
        break;
      case "Addendum Sale of Other Property by Buyer":
           formFieldData = addendumSaleofOtherPropertybyBuyer();
        break;
      case "Addendum Seller Financing":
           formFieldData = addendumSellerFinancing();
           break;
      case "Addendum Short Sale":
           formFieldData = addendumShortSale();
           break;
      case "Addendum Third Party Financing":
           formFieldData = addendumThirdPartyFinancing();
        break;
      
      case "Amendment":
           formFieldData = amendment();
        break;
      case "Buyers Temporary Residential Lease":
           formFieldData = buyersTemporaryResidentialLease();
           break;
      case "Condominium Resale Certificate":
           formFieldData = condominiumResaleCertificate();
        break;
      case "Disclosure of Relationship with Contract Provider":
           formFieldData = disclosureofRelationshipwithContractProvider();
           break;
      case "Farm and Ranch Contract":
           formFieldData = farmandRanchContract();
        break;
      case "Information About Brokerage Services":
           formFieldData = informationAboutBrokerageServices();
           break;
      case "Landlord Floodplain and Flood Notice":
           formFieldData = landlordFloodplainandFloodNotice();
           break;
       case "New Home Contract (Completed Construction)":
           formFieldData = newHomeContractCompletedConstruction();
        break;
      case "New Home Contract (Incomplete Construction)":
           formFieldData = newHomeContractIncompleteConstruction();
           break;
      case "Notice of Buyers Termination":
           formFieldData = noticeofBuyersTermination();
           break;
      case "Notice to Purchaser of Special Taxing District":
           formFieldData = noticetoPurchaserofSpecialTaxingDistrict();
           break;
      case "One to Four Family Residential Contract":
           formFieldData = onetoFourFamilyResidentialContract();
        break;
      case "Residential Buyer Representation":
           formFieldData = residentialBuyerRepresentation();
           break;
      case "Residential Condominium Contract":
           formFieldData = residentialCondominiumContract();
        break;
      case "Sellers Disclosure Notice":
           formFieldData = sellersDisclosureNotice();
           break;
      case "Sellers Temporary Residential Lease":
           formFieldData = sellersTemporaryResidentialLease();
        break;
      case "Subdivision and Property Owners Association":
           formFieldData = subdivisionandPropertyOwnersAssociation();
        break;
      case "Unimproved Property Contract":
           formFieldData = unimprovedPropertyContract();
           break;
      
      default:
        formFieldData = emptyForm();
    }
  }
  else if (transaction?.agentProfile?.state === "Louisiana") {
    switch (formTitle) {
      case "Agency Disclosure Form":
        formFieldData = agencyDisclosureForm();
        break;
      case "Agency Disclosure Dual Agent":
           formFieldData = agencyDisclosureDualAgent();
           break;

      // case "Agency Disclosure Pamphlet":     // landscape mode doesn't work
      //      formFieldData = agencyDisclosurePamphlet();
      //      break;
      case "Buy Sell Agreement 2019":
        formFieldData = buySellAgreement2019();
        break;
      case "Buyer Agreement Form Long":
          //  formFieldData = buyerAgreementFormLong();
           break;
      case "Condominium Addendum":
           formFieldData = condominiumAddendum();
        break;
      case "Deposit Addendum":
           formFieldData = depositAddendum();
           break;
      case "Exclusive Buyer Representations Agreement":
        formFieldData = exclusiveBuyerRepresentationsAgreement();
        break;
      case "Historic District Disclosure":
           formFieldData = historicDistrictDisclosure();
           break;
      case "Inspection Response Addenda":
           formFieldData = inspectionResponseAddenda();
           break;
      case "Lead Based Paint Warning":
        formFieldData = leadBasedPaintWarning();
        break;
      case "New Construction Addendum":
           formFieldData = newConstructionAddendum();
        break;
      case "Property Disclosure Document":
           formFieldData = propertyDisclosureDocument();
           break;
      case "Residential Agreement to Buy or Sell":
           formFieldData = residentialAgreementtoBuyorSell();
        break;
      case "Sewer Treatment Systems Addendum":
           formFieldData = sewerTreatmentSystemsAddendum();
        break;
      case "Waiver of Warranty":
           formFieldData = waiverofWarranty();
           break;

      default:
        formFieldData = emptyForm();
    }
  }
  else {
    switch (formTitle) {
      // Alpha order except brokerages up front

      // COX only in Sunrise 
      case "Additional Designated Broker (DC-6-16)":
           formFieldData = additionalDesignatedBrokerDC616();
        break;
      case "Buyer Advisory (Residential) (DC-9-24)":
           formFieldData = buyerAdvisoryResidentialDC924();
        break;
      case "Seller Advisory (Residential) (DC-9-24)":
           formFieldData = sellerAdvisoryResidentialDC924();
        break;
      case "Termination Agreement - Buyer (DC-5-23)":
           formFieldData = terminationAgreementBuyerDC523();
        break;
      case "Termination Agreement - Seller (DC-5-23)":
           formFieldData = terminationAgreementSellerDC523();
           break;

      // MLS CREN
      case "CREN Delayed Entry Form":
        formFieldData = cRENDelayedEntryForm();
        break;
      // *** Colorado - Next Home Virtual
      case "Commission Disbursement NextHomeVirtual":
           formFieldData = commissionDisbursementNextHomeVirtual();
        break;
      
      // *** COLORADO - COLDWELL BANKER *** Affiliated, Mold, Radon, Wire, Personal xact
      case "CB Colorado Affiliated Business Disclosure":
        formFieldData = cBColoradoAffiliatedBusinessDisclosure();
        break;
      case "CB FSBO Agreement for Payment of Commission":
           formFieldData = cBFSBOAgreementforPaymentofCommission();
           break;
      case "CB Exclusive Right to Buy 2023":
        formFieldData = cBExclusiveRighttoBuy2023();
        break;

      case "Exclusive Right to Buy CB 2024":
           formFieldData = exclusiveRighttoBuyCB2024();
           break;
      case "CB Exclusive Right to Sell 2023":
        formFieldData = cBExclusiveRighttoSell2023();
        break;
      case "Exclusive Right to Sell 2024 CB":
        formFieldData = exclusiveRighttoSell2024CB();
        break;
      case "Exclusive Right to Sell 2024-Aug":
           formFieldData = exclusiveRighttoSell2024Aug();
           break;
      case "CB Mold Disclosure":
        formFieldData = cBMoldDisclosure();
        break;
      case "CB Sales Associate Election Personal Transactions":
        formFieldData = cBSalesAssociateElectionPersonalTransactions();
        break;
      case "CB Radon Disclosure":
        formFieldData = cBRadonDisclosure();
        break;
      case "CB Wire Fraud Disclosure":
        formFieldData = cBWireFraudDisclosure();
        break;
      case "Disclosure of Buyer Broker Compensation 9-23-24":
           formFieldData = disclosureofBuyerBrokerCompensation92324();
           break;
      case "Exclusive Right to Buy CB 2024 Aug":
           formFieldData = exclusiveRighttoBuyCB2024Aug();
           break;
           case "Exclusive Right to Sell CB 2024 Aug":
           formFieldData = exclusiveRighttoSellCB2024Aug();
        break;
      case "Exclusive Right to Sell CB 2024-09":
           formFieldData = exclusiveRighttoSellCB202409();
           break;
      // Sub-Brokerage CBC for custom edit permission on $259 fee
      case "CBC Exclusive Right to Buy 2023":
        formFieldData = cBCExclusiveRighttoBuy2023();
        break;
      case "CBC Exclusive Right to Sell 2023":
        formFieldData = cBCExclusiveRighttoSell2023();
        break;
      case "RealVitalize Seller Property Agreement":
           formFieldData = realVitalizeSellerPropertyAgreement();
           break;

      // *** Coldwell Banker Distinctive Properties *** CBDP = Team VM
      case "CBDP Affiliated Businesses 2022":
        formFieldData = cBDPAffiliatedBusinesses2022();
        break;
      case "Affiliated Business Disclosure CBDP":
        formFieldData = affiliatedBusinessDisclosureCBDP();
        break;
      case "Affiliated Business Disclosure CB-DP":
        formFieldData = affiliatedBusinessDisclosureCB_DP();
        break;
      case "Home Warranty AHS 2023":
        formFieldData = homeWarrantyAHS2023();  // moved to all
        break;
      case "Termination of Marketing Efforts CBDP":
           formFieldData = terminationofMarketingEffortsCBDP();
        break;
       case "Termination of Marketing Efforts CB-DP":
           formFieldData = terminationofMarketingEffortsCB_DP();
           break;

      // *** HomeSmart ***
       case "Affiliate Business Arrangement HSPR":
        formFieldData = affiliateBusinessArrangementHSPR();
        break;
      case "FSBO Commission Agreement HSPR":
        formFieldData = fSBOCommissionAgreementHSPR();
        break;
      case "Home Warranty & Inspection Form HSPR":
        formFieldData = homeWarrantyInspectionFormHSPR();
        break;
      case "Introlend and Preferred Title ABA HSPR":
        formFieldData = introlendandPreferredTitleABAHSPR();
        break;
      case "Listing Termination Agreement HSPR":
        formFieldData = listingTerminationAgreementHSPR();
        break;
      case "Referral Agreement Originating Agent HSPR":
        formFieldData = referralAgreementOriginatingAgentHSPR();
        break;
      case "Referral Agreement Receiving Agent HSPR":
        formFieldData = referralAgreementReceivingAgentHSPR();
        break;

      // *** RE/MAX ***
      case "REMAX Wire Fraud Alert":
        formFieldData = rEMAXWireFraudAlert();
        break;

    // *** REDBOW ***
    case "RB Commissions Disclosure 1023":
      formFieldData = rBCommissionsDisclosure1023();
      break;
    case "RB Home Inspection Waiver Agreement23":
      formFieldData = rBHomeInspectionWaiverAgreement23();
      break;
    case "RB Broker Rebate to Buyer 2022":
      formFieldData = rBBrokerRebatetoBuyer2022();
      break;
    case "RB Seller Coming Soon Addendum 2022":
      formFieldData = rBSellerComingSoonAddendum2022();
      break;

      // *** Extra bonus forms Colorado ***
    case "Addendum Blank":
      formFieldData = addendumBlank();
        break;
      // CO BAR
      case "Brokerage Firm Compensation Agreement":
           formFieldData = brokerageFirmCompensationAgreement();
           break;
      // *** Colorado Forms ***
    case "Agreement to Revive":
      formFieldData = agreementtoRevive();
      break;
    case "Amend-Extend Contract":
      formFieldData = amendExtendContract();
      break;
    case "Amend-Extend Contract 2023":
      formFieldData = amendExtendContract2023();
      break;
    case "Amend-Extend Without Dates":
      formFieldData = amendExtendWithoutDates();
      break;
    case "Appraisal Value Objection Notice":
      formFieldData = appraisalValueObjectionNotice();
      break;
    case "Assignment of Lease":
      formFieldData = assignmentofLease();
      break;
    case "Bill of Sale (Contract to Buy and Sell)":
      formFieldData = billofSaleContract();
      break;
    case "Bill of Sale (PPA)":
      formFieldData = billofSalePPA();
      break;
    case "Brokerage Disclosure to Buyer":
      formFieldData = brokerageDisclosuretoBuyer();
        break;
      case "Brokerage Disclosure to Buyer 2024":
           formFieldData = brokerageDisclosuretoBuyer2024();
           break;
    case "Brokerage Disclosure to Seller (FSBO)": //FSBO
      formFieldData = brokerageDisclosuretoSellerFSBO();
      break;
    case "Brokerage Duties Disclosure to Seller (non-CREC Listing)":
      formFieldData = brokerageDutiesDisclosuretoSellernonCRECListing();
      break;
    case "Buyer Contract Amend-Extend 2023":
      formFieldData = buyerContractAmendExtend2023();
      break;
    case "Change of Status":
      formFieldData = changeofStatus();
      break;
    case "Closing Instructions":
      formFieldData = closingInstructions();
      break;
    case "Contract to Buy and Sell, Commercial":
      formFieldData = contracttoBuyandSellCommercial();
        break;
      case "Contract to Buy and Sell, Commercial 2024":
           formFieldData = contracttoBuyandSellCommercial2024();
           break;
    case "Contract to Buy and Sell, CO Foreclosure Protection Act":
      formFieldData = contracttoBuyandSellCOForeclosureProtectionAct();
      break;
    case "Contract to Buy and Sell, Foreclosure 2023":
      formFieldData = contracttoBuyandSellForeclosure2023();
        break;
      case "Contract to Buy and Sell, Foreclosure 2024":
           formFieldData = contracttoBuyandSellForeclosure2024();
           break;
    case "Contract to Buy and Sell, Land":
      formFieldData = contracttoBuyandSellLand();
      break;
    case "Contract to Buy and Sell, Land 2024":
      formFieldData = contracttoBuyandSellLand2024();
        break;
      case "Contract to Buy and Sell, Land 2024 Aug":
           formFieldData = contracttoBuyandSellLand2024Aug();
           break;
    case "Contract to Buy and Sell, Income Residential":
      formFieldData = contracttoBuyandSellIncome();
      break;
    case "Contract to Buy and Sell, Income Residential 2023":
      formFieldData = contracttoBuyandSellIncomeResidential2023();
        break;
      case "Contract to Buy and Sell, Income Residential 2024":
           formFieldData = contracttoBuyandSellIncomeResidential2024();
           break;
    case "Contract to Buy and Sell, Residential":
      formFieldData = contracttoBuyandSellResidential();
      break;
    case "Contract to Buy and Sell, Residential 2023":
      formFieldData = contracttoBuyandSellResidential2023();
        break;
      case "Contract to Buy and Sell, Residential 2024":
           formFieldData = contracttoBuyandSellResidential2024();
           break;
    case "Counterproposal":
      formFieldData = counterproposal();
      break;
    case "Counterproposal 2023":
      formFieldData = counterproposal2023();
        break;
    case "Counterproposal Without Dates":
      formFieldData = counterproposalWithoutDates();
      break;
    case "Definitions of Working Relationships (for Sellers)":
      formFieldData = definitionsofWorkingRelationshipsForSellers();
      break;
    case "Definitions of Working Relationships (for Buyers)":
      formFieldData = definitionsofWorkingRelationshipsForBuyers();
      break;
    case "Earnest Money Release":
      formFieldData = earnestMoneyRelease();
      break;
    case "Earnest Money Receipt":
      formFieldData = earnestMoneyReceipt();
      break;
    case "Energy Benchmarking Disclosure":
      formFieldData = energyBenchmarkingDisclosure();
      break;
    case "Estoppel Statement":
      formFieldData = estoppelStatement();
        break;
    case "Exclusive Brokerage Addendum to Listing Contract":
      formFieldData = exclusiveBrokerageAddendumtoListingContract();
      break;
    case "Exclusive Right to Buy":
      formFieldData = exclusiveRighttoBuy();
      break;
    case "Exclusive Right to Buy 2023":
      formFieldData = exclusiveRighttoBuy2023();
      break;
      case "Exclusive Right to Buy 2024 Aug":
           formFieldData = exclusiveRighttoBuy2024Aug();
           break;
    case "Exclusive Right to Sell":
      formFieldData = exclusiveRighttoSell();
      break;
    case "Exclusive Right to Sell 2024":
      formFieldData = exclusiveRighttoSell2024();
        break;
      case "Exclusive Right to Sell 2024 Aug":
           formFieldData = exclusiveRighttoSell2024Aug();
           break;

    case "Extension or Termination":
      formFieldData = extensionorTermination();
      break;
    case "Exchange Addendum to Contract to Buy and Sell Real Estate":
      formFieldData = exchangeAddendumtoContracttoBuySellRealEstate();
        break;
    case "Fort Collins Occupancy Disclosure":
      formFieldData = fortCollinsOccupancyDisclosure();
      break;
    case "Green Disclosure":
      formFieldData = greenDisclosure();
      break;
    case "Inspection Objection Notice":
      formFieldData = inspectionObjectionNotice();
      break;
    case "Inspection Objection Notice 2023":
      formFieldData = inspectionObjectionNotice2023();
      break;
    case "Inspection Resolution":
      formFieldData = inspectionResolution();
      break;
    case "Inspection Resolution 2023":
      formFieldData = inspectionResolution2023();
      break;
    case "Lead Based Paint Disclosure (Sales)":
      formFieldData = leadBasedPaintDisclosureSalesWritable1();
      break;
    case "Lead-Based Paint Obligations of Seller":
      formFieldData = leadBasedPaintObligationsofSeller();
      break;
    case "Listing Contract Amend-Extend":
      formFieldData = listingContractAmendExtendCleanWritable2020();
      break;
    case "Listing Contract Amend-Extend 2023":
      formFieldData = listingContractAmendExtend2023();
      break;
    case "Manufactured Home Addendum 2024":
      formFieldData = manufacturedHomeAddendum2024();
      break;
    case "Manufactured Home Amend Extend 2024":
      formFieldData = manufacturedHomeAmendExtend2024();
      break;
    case "Manufactured Home CBS Lot Lease 2024":
      formFieldData = manufacturedHomeCBSLotLease2024();
      break;
    case "Manufactured Home Counterproposal 2024":
      formFieldData = manufacturedHomeCounterproposal2024();
      break;
    case "Notice to Terminate":
      formFieldData = noticeToTerminate();
      break;
    case "Notice to Terminate (Buyer)":
      formFieldData = noticetoTerminateBuyer();
      break;
    case "Notice to Terminate (Seller)":
      formFieldData = noticetoTerminateSeller();
      break;
    case "Open Listing Addendum to Listing Contract":
      formFieldData = openListingAddendumtoListingContract();
      break;
    case "ORDER TITLE FORM":
      formFieldData = oRDERTITLEFORM();
      break;
    case "Personal Property Agreement":
      formFieldData = personalPropertyAgreement();
      break;
    case "Post-Closing Occupancy Agreement":
      formFieldData = postClosingOccupancyAgreement();
      break;
    case "Residential Addendum to Contract":
      formFieldData = residentialAddendumtoContracttoBuySellRealEstate();
      break;
    case "Residential Addendum to Contract 2024":
      formFieldData = residentialAddendumtoContract2024();
        break;
    case "Seller Warning (CO Foreclosure Protection Act)":
      formFieldData = sellerWarningCOForeclosureProtectionAct();
        break;
      case "Sellers Property Disclosure (Commercial)":
           formFieldData = sellersPropertyDisclosureCommercial();
           break;
    case "Sellers Property Disclosure (Residential)":
      formFieldData = sellersPropertyDisclosureResidential();
      break;
    case "Sellers Property Disclosure (Residential) 2023":
      formFieldData = sellersPropertyDisclosureResidential2023();
      break;
    case "Sellers Property Disclosure (Land) 2024":
      formFieldData = sellersPropertyDisclosureLand2024();
      break;
    case "Sellers Property Disclosure (Land)":
      formFieldData = sellersPropertyDisclosureLand();
      break;
    case "Sellers Property Disclosure Supplement (Additional Structure)":
      formFieldData = sellersPropertyDisclosureSupplementAdditionalStructure();
      break;
    case "Short Sale Addendum (CBS)":
      formFieldData = shortSaleAddendumCBS();
      break;
    case "Short Sale Addendum (Seller Listing Contract)":
      formFieldData = shortSaleAddendumSellerListingContract();
      break;
    case "Square Footage Disclosure":
      formFieldData = squareFootageDisclosure();
      break;
    case "Source of Water Addendum":
      formFieldData = sourceOfWaterAddendum();
      break;
    case "Title-Related Objection Notice":
      formFieldData = titleRelatedObjectionNotice();
      break;

    case "cOVID19Addendum2020":
      formFieldData = cOVID19Addendum2020();
      break;

      default:
        formFieldData = emptyForm();
    }
  }
  return formFieldData;
}
